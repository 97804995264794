import { Pager, usePager } from "@wigxel/react-components/lib/layout"
import React from "react"
import t from "prop-types"

const Alumni = ({ testimonials }) => {
  const intervalId = React.useRef(0)
  const direction = React.useRef(false)
  const { next, prev, currentPage, goto } = usePager()

  const items = testimonials.nodes || []

  const _predictDirection = () => {
    direction.current =
      currentPage === items.length - 1
        ? false
        : currentPage == 0
        ? true
        : direction.current
    direction.current ? next() : prev()
  }

  const _goto = idx => {
    _clearInterval()
    goto(idx)
  }

  const _resumeAnimation = () => {
    intervalId.current = setInterval(() => {
      _predictDirection()
    }, 8000)
  }

  const _clearInterval = () => {
    clearInterval(intervalId.current)
  }

  React.useEffect(() => {
    _resumeAnimation()
    return _clearInterval
  })

  return (
    <section className="container flex flex-col items-center mx-auto px-4 md:px-0">
      <span>TESTIMONIALS FROM OUR</span>
      <h2 className="font-display text- font-bold tracking-tight text-4xl mb-2 lg:text-6xl md:mb-8">
        Alumni
      </h2>

      <div
        className="rounded-lg max-w-lg shadow-xl relative w-full"
        onMouseEnter={_clearInterval}
        onMouseLeave={_resumeAnimation}
      >
        <span className="text-7xl absolute top-0 left-0 transform translate-y-6 -translate-x-4 text-primary">
          ````
        </span>
        <Pager morph={true}>
          {items.map(node => {
            const { url: imageUrl } = node.photo?.file || {}

            return (
              <div
                key={node.id}
                className="text-sm md:text-base py-14 px-14 md:py-20 md:px-20 flex flex-col-reverse gap-4 select-none"
              >
                <p>{node.content?.content || "{{Content}}"}</p>
                <div className="flex items-center">
                  <figure className="w-12 h-12 lg:w-16 lg:h-16 flex-shrink-0 relative mr-4 inline-block">
                    <img
                      src={imageUrl}
                      alt={node.fullname || "Figure"}
                      className="w-12 h-12 lg:w-16 lg:h-16 object-cover rounded-full relative z-10 bg-gray-200"
                    />
                    <img
                      src={imageUrl}
                      alt={node.fullname || "Figure"}
                      className="w-12 h-12 lg:w-16 lg:h-16 absolute object-cover rounded-full overflow-hidden top-3 opacity-50 z-0"
                      style={{ filter: "blur(6px)" }}
                    />
                  </figure>
                  <div className="flex flex-col">
                    <span className="font-bold whitespace-nowrap text-gray-700">
                      {node.fullname}
                    </span>
                    <span className="text-sm text-_5 opacity-75">
                      {node.titleAndCompany || "Ex-student"}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </Pager>
      </div>
      <div className="grid gap-4 grid-flow-col mt-12 justify-center items-center mx-auto">
        {items.map((_, idx) => (
          <div
            key={idx}
            className={`w-4 h-4 rounded-lg ${
              currentPage === idx ? "bg-primary" : "bg-gray-200"
            }`}
            onClick={() => _goto(idx)}
          />
        ))}
      </div>
    </section>
  )
}

Alumni.propTypes = {
  testimonials: t.object.isRequired,
}

export default Alumni
