import React from "react"
import * as R from "ramda"
import t from "prop-types"
import { Link } from "gatsby"
import { ArrowRight } from "react-feather"
import { slugify } from "@wigxel/utils"
import { H3 } from "../Typography"
import { naira } from "../../libs/utils"

const notNull = R.complement(R.isNil)

const CourseCard = props => (
  <div className="w-full flex flex-col rounded-lg shadow-lg mt-5 select-none">
    <figure
      className="bg-gray-200 transform -translate-y-5 translate-x-5 rounded-lg shadow-xl"
      style={{
        height: `${24 * 8}px`,
        backgroundSize: "cover",
        backgroundImage: `url(${props.image})`,
      }}
    />
    <div className="px-6 pb-6 pt-0 flex-1 flex flex-col justify-between">
      <article>
        <H3 style={{ letterSpacing: -1 }}>{props.title}</H3>
        <ul className="grid grid-cols-2 mt-4 mb-2">
          <li className="block text-lg opacity-75">
            <div className="text-xs uppercase">PRICE</div>
            &#x20a6;{naira(props.price)}
          </li>
          {(props.benefits || []).filter(notNull).map(e => (
            <li className="block opacity-75 text-lg" key={e}>
              <div className="text-xs uppercase">Duration</div>
              {e}
            </li>
          ))}
        </ul>
      </article>

      <div className="flex justify-end translate-x-10 transform">
        <Link
          to={`/courses/${slugify(props.title)}`}
          className="text-primary mt-8 inline-flex items-center"
        >
          <span>Learn More</span> <ArrowRight className="inline-block ml-2" />
        </Link>
      </div>
    </div>
  </div>
)

CourseCard.propTypes = {
  title: t.string.isRequired,
  benefits: t.array,
  image: t.string.isRequired,
  price: t.string.isRequired,
}

export default CourseCard
